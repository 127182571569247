<template>
  <div v-if="show">
    <h1 v-if="stagelineConfig?.show_company_name" class="mt-0" style="font-weight: 800">
      {{ company.name }}
    </h1>
    <div v-if="stagelineConfig?.show_progress_bar" class="mb-5">
      <h6>
        {{ currentPeriod.title }}
      </h6>
      <stageline-progress-bar />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyNameAndProgressBar',
  components: {
    StagelineProgressBar: () => import('@/components/StagelineV2/shared/StagelineProgressBar'),
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'currentPeriod',
      'currentSlide',
      'stagelineConfig',
    ]),
    show() {
      return this.stagelineConfig?.show_company_name ||
             this.stagelineConfig?.show_progress_bar
    },
  },
}
</script>

<style lang="scss" scoped></style>
